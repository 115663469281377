import React, { useContext } from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ContainerLayout from "../../components/ui/containerLayout"

import { useI18n, useCustomI18n } from "../../../locale.js"
import { ServerDataContext } from "../../../serverData";

import certificateBg from "../../assets/images/bg/certificateCosmicBg.jpg"
import controllerLeft from "../../assets/images/elements/controllerLeft.png"
import controllerRight from "../../assets/images/elements/controllerRight.png"
import greenBrackets from "../../assets/images/elements/greenSmallBrackets.svg"
import fallingStar from "../../assets/images/elements/fallingStar.svg"
import flashStar from "../../assets/images/elements/flashStar.png"
import giftCard from '../../assets/images/gift.svg'

const Landing = ({ city }) => {
  const breakpoints = useBreakpoint()
  const { details } = useContext(ServerDataContext);

  const priceData = details.schedule || city.prices;

  return (
    <WrapStyled>
      <ContainerLayout id="prices">
        <FallingStarStyled src={fallingStar} alt="Falling star" />
        {breakpoints.tablet ? null : (
          <FlashStarStyled src={flashStar} alt="Flashing star" />
        )}
        <GiftCardImageCOntainerStyled>
          <GiftCardImageStyled src={giftCard} alt='Gift certificate' />
        </GiftCardImageCOntainerStyled>

        <TitleStyled>
          {useI18n('gift.firstScreen.title1')}{" "}
          <b>
            {useI18n('gift.firstScreen.title2')}
            <br /> {useI18n('gift.firstScreen.title3')}
          </b>
        </TitleStyled>

        <SubtitleStyled>
          {useI18n('gift.firstScreen.details1')}
          <br /> {useI18n('gift.firstScreen.details2')}
          <br /> {useI18n('gift.firstScreen.details3')}
        </SubtitleStyled>

        <PriceBlockStyled>
          <PriceTextStyled>
            {useI18n('gift.firstScreen.cost')}{" "}{useCustomI18n(city.declension)}
          </PriceTextStyled>
          <PriceValueStyled>
            {priceData.sunday ? (priceData.sunday[0].custom?.certificate || priceData.sunday[0].price) : priceData.weekend} <span>{useI18n('gift.firstScreen.currency')}</span>
          </PriceValueStyled>
          <PriceTextStyled>
            {useI18n('gift.firstScreen.hint')}
          </PriceTextStyled>
        </PriceBlockStyled>
      </ContainerLayout>
    </WrapStyled>
  )
}
export default Landing

const GiftCardImageStyled = styled.img`
  width: 350px;
  height: 220px;
  max-width: 350px;
  max-height: 220px;
  transform: skew(24deg,-21deg);
  margin-bottom: 100px;
    @media ${props => props.theme.breakpoints.small} {
    max-width: 100%;
    wihth: 100%;
    height: auto;
  }
`
const GiftCardImageCOntainerStyled = styled.div`
display: flex;
max-width: 350px;
margin: 0 auto;
  @media ${props => props.theme.breakpoints.small} {
    max-width: 65%;
  }
`;

const WrapStyled = styled.div`
  padding-top: 100px;

  position: relative;

  background-image: url(${certificateBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  > * {
    z-index: 1;
  }

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: rgba(3, 3, 3, 0.2);
  }

  @media ${props => props.theme.breakpoints.laptop} {
    padding-top: 80px;
  }

  @media ${props => props.theme.breakpoints.tablet} {
    padding-top: 60px;
    &:before {
      background: rgba(3, 3, 3, 0.5);
    }
  }
`

const TitleStyled = styled.p`
  margin-bottom: 2rem;

  font-size: 3.8rem;
  line-height: 1.28;
  text-align: center;
  br {
    display: none;
  }

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7rem;
    br {
      display: block;
    }
  }
`

const SubtitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 1.5rem;
  padding: 14px 60px;

  position: relative;

  font-size: 2.2rem;
  text-align: center;
  line-height: 1.1;

  z-index: 1;

  // &:before {
  //   content: "";
  //   width: 24px;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;

  //   background-image: url(${greenBrackets});
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   transform: rotate(180deg);
  // }

  // &:after {
  //   content: "";
  //   height: 100%;
  //   width: 24px;
  //   position: absolute;
  //   top: 0;
  //   right: 0;

  //   background-image: url(${greenBrackets});
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  //   background-position: center;
  // }

  @media ${props => props.theme.breakpoints.mobile} {
    width: unset;
  }
`

const PriceBlockStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 60px 0px;

  position: relative;

  &:before {
    content: "";
    width: 200px;
    height: 200px;
    position: absolute;
    top: 40%;
    left: -80%;

    background-image: url(${controllerLeft});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:after {
    content: "";
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    right: -80%;

    background-image: url(${controllerRight});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  @media ${props => props.theme.breakpoints.small} {
    &:before {
      width: 150px;
      height: 150px;

      left: -36%;
      top: -45%;
    }
    &:after {
      width: 150px;
      height: 150px;

      right: -36%;
      top: -70%;
    }
  }
  @media ${props => props.theme.breakpoints.mobile} {
    &:before {
      left: -24%;
      top: -45%;
    }
    &:after {
      right: -24%;
      top: -70%;
    }
  }
`

const PriceTextStyled = styled.p`
  font-size: 1.1rem;
  text-align: center;
  line-height: 1.41;
`

const PriceValueStyled = styled.p`
  font-size: 3.2rem;
  text-align: center;
  line-height: 1.41;
  font-family: ${props => props.theme.fonts.futuraDemi};
  color: ${props => props.theme.colors.green};

  span {
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.futura};
  }

  @media ${props => props.theme.breakpoints.small} {
    font-size: 2.6rem;
  }
`

const FallingStarStyled = styled.img`
  width: 120px;
  height: 60px;

  position: absolute;
  left: 0%;
  top: 25%;

  @media ${props => props.theme.breakpoints.tablet} {
    width: 100px;
    height: 45px;

    left: 25%;
    top: 2%;
  }
`

const FlashStarStyled = styled.img`
  width: 60px;
  height: 60px;

  position: absolute;
  right: 10%;
  top: 10%;
`
