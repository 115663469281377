import React from "react";
import styled from "styled-components";

import ContainerLayout from "../../components/ui/containerLayout";
import SocialsBlock from "../../components/socialsBlock";

import { useI18n } from "../../../locale.js";

import certificateBg from "../../assets/images/bg/certificateCosmicBg.jpg";
import tgboticon from '../../assets/images/elements/tgbot.png'
import insticon from '../../assets/images/elements/instagram.png'
import fbicon from '../../assets/images/elements/facebook.png'

const Contacts = ({city}) => {
  return (
    <WrapperStyled>
      <ContainerLayoutStyled id="contacts">
        <TitleStyled>
          <b>
            {useI18n("gift.contact.us")}
            <br /> {useI18n("gift.contact.purchase")}
          </b>
        </TitleStyled>

        <BlockStyled>
          <LinkStyled href="tel:+380981885522">+38 098 188 55 22</LinkStyled>
          <LinkStyled href="tel:+380991885522">+38 099 188 55 22</LinkStyled>
          <LinkStyled href="tel:+380931885522">+38 093 188 55 22</LinkStyled>
        </BlockStyled>

        <WrapperSocial>
              <SocLink rel="noopener noreferrer" href={city.links.inst} target="_blank">
                <SocImage src={insticon} alt={`Instagram Matrix ${city.name.en}`} title={`Instagram Matrix ${city.name.en}`} />
                </SocLink>
              <SocLink rel="noopener noreferrer" href={city.links.fb} target="_blank">
                <SocImage src={fbicon} alt={`Facebook Matrix ${city.name.en}`} title={`Facebook Matrix ${city.name.en}`} />
                </SocLink>
              <SocLink rel="noopener noreferrer" href={city.links.tgbot} target="_blank">
                <SocImageBot src={tgboticon} alt={`Telegram Bot Matrix ${city.name.en}`} title={`Telegram Bot Matrix ${city.name.en}`} />
                </SocLink>
            </WrapperSocial>
        {/* <SocialsBlock showOnMobile showAlways /> */}
      </ContainerLayoutStyled>
    </WrapperStyled>
  );
};
export default Contacts;

const SocLink = styled.a`
opacity:1;
transition: all 0.3s ease-in-out;
&:hover{
opacity:0.8;
}
`

const SocImage = styled.img`
width: 41px;
height: 41px;
`

const SocImageBot = styled.img`
width: 56px;
height: 41px;
margin-left: -3px;
`
const WrapperSocial = styled.div`
display: flex;
gap: 1.5rem;
margin-top: 1rem;
`

const WrapperStyled = styled.div`
  position: relative;
  /* margin-top: -6.5rem; */
  /* padding-top: 8.5rem; */

  > * {
    z-index: 1;
  }

  &:before {
    content: "";

    height: 100%;
    width: 100%;
    transform: rotate(180deg);

    position: absolute;
    top: 0;

    background-image: url(${certificateBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &:after {
    content: "";

    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;

    background: linear-gradient(
      to bottom,
      ${(props) => props.theme.colors.black},
      5%,
      rgba(3, 3, 3, 0.1)
    );
  }

  @media ${(props) => props.theme.breakpoints.small} {
    margin-top: 0;
    padding-top: 0;
    &:before {
      content: unset;
    }
  }
`;

const ContainerLayoutStyled = styled(ContainerLayout)`
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
`;

const TitleStyled = styled.p`
  font-size: 2.5rem;
  text-align: center;
  line-height: 1.1;

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
  @media ${(props) => props.theme.breakpoints.mobile} {
    br {
      display: none;
    }
  }
`;

const BlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 50px 0;

  text-align: center;
  br {
    display: none;
  }
  @media ${(props) => props.theme.breakpoints.tablet} {
    align-items: center;
    margin: 50px 0 0px;
    br {
      display: block;
    }
  }
`;
const LinkStyled = styled.a`
  color: ${(props) => props.theme.colors.white};
  font-size: 1.5rem;
  line-height: 1.4;

  &:hover {
    color: ${(props) => props.theme.colors.green};
  }

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.4rem;
  }
`;
