import React from "react";
import styled from "styled-components";

import ContainerLayout from "../../components/ui/containerLayout";

import { useI18n } from "../../../locale.js";

import timerCircle from "../../assets/images/about/timerCircle.svg";
import gamepadCircle from "../../assets/images/about/gamepadCircle.svg";
import giftCircle from "../../assets/images/about/giftCircle.svg";
import peopleCircle from "../../assets/images/about/peopleCircle.svg";

import sphereImage from "../../assets/images/elements/sphereImage.png";

const CardDescription = () => {
  return (
    <ContainerLayout>
      <SphereImageStyled src={sphereImage} alt="Sphere" />

      <TitleStyled>
        <b>{useI18n("gift.description.title")}</b>
      </TitleStyled>

      <SubtitleStyled>
        {useI18n("gift.description.details1")}
        <br />
        {useI18n("gift.description.details2")}
      </SubtitleStyled>

      <WrapperStyled>
        <ItemStyled>
          <ItemImageStyled src={timerCircle} alt="Timer" />
          <ItemDescriptionStyled>
            {useI18n("gift.description.unlimited")}
          </ItemDescriptionStyled>
        </ItemStyled>
        <ItemStyled>
          <ItemImageStyled src={peopleCircle} alt="Closed club" />
          <ItemDescriptionStyled>
            {useI18n("gift.description.family")}
          </ItemDescriptionStyled>
        </ItemStyled>
        <ItemStyled>
          <ItemImageStyled src={gamepadCircle} alt="Gamepad" />
          <ItemDescriptionStyled>
            {useI18n("gift.description.games")}
          </ItemDescriptionStyled>
        </ItemStyled>
        <ItemStyled>
          <ItemImageStyled src={giftCircle} alt="Team play" />
          <ItemDescriptionStyled>
            {useI18n("gift.description.thanks")}
          </ItemDescriptionStyled>
        </ItemStyled>
      </WrapperStyled>
    </ContainerLayout>
  );
};
export default CardDescription;

const WrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 5rem;
  /* margin-bottom: -3rem; */
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.small} {
    margin-top: 2rem;
    /* margin-bottom: 0; */
  }
`;

const TitleStyled = styled.p`
  margin-bottom: 2.2rem;

  font-size: 2.5rem;
  line-height: 1.14;
  text-align: center;
  z-index: 1;

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`;
const SubtitleStyled = styled.p`
  font-size: 1.2rem;
  line-height: 1.14;
  text-align: center;
  z-index: 1;
  @media ${(props) => props.theme.breakpoints.small} {
    padding: 0 1rem;
    margin-bottom: 3rem;

    font-size: 1rem;
    br {
      display: none;
    }
  }
`;

const ItemStyled = styled.div`
  display: flex;
  align-items: center;

  width: 45%;
  margin: 0 auto 3rem;

  @media ${(props) => props.theme.breakpoints.small} {
    width: 100%;
    margin: 0 0 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const ItemImageStyled = styled.img`
  margin-right: 15px;
  width: 140px;
  height: 140px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 85px;
    height: 85px;
  }
`;

const ItemDescriptionStyled = styled.p`
  font-size: 1rem;
  line-height: 1.1;

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 0.9rem;
  }
`;

const SphereImageStyled = styled.img`
  opacity: 0.3;
  position: absolute;

  top: -15%;
  left: -15%;

  width: 300px;
  height: 300px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    left: -35%;
    top: 0;
  }
`;
