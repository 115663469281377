import React from "react"

const Icon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 0H0V40H40V0ZM34.7368 8.6323C34.7368 9.91127 33.7 10.9481 32.421 10.9481C31.142 10.9481 30.1052 9.91127 30.1052 8.6323C30.1052 7.35332 31.142 6.31651 32.421 6.31651C33.7 6.31651 34.7368 7.35332 34.7368 8.6323ZM30.1053 20.2117C30.1053 25.3276 25.958 29.4748 20.8421 29.4748C15.7262 29.4748 11.579 25.3276 11.579 20.2117C11.579 15.0958 15.7262 10.9485 20.8421 10.9485C25.958 10.9485 30.1053 15.0958 30.1053 20.2117Z"
      fill="white"
    />
  </svg>
)
export default Icon
