import * as React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ContainerLayout from "../../components/ui/containerLayout"

import { useI18n } from "../../../locale.js"

import sphereImage from "../../assets/images/elements/sphereImage.png"
import greenBrackets from "../../assets/images/elements/greenSmallBrackets.svg"
import fallingStar from "../../assets/images/elements/fallingStar.svg"
import ruler from "../../assets/images/elements/ruler.svg"

const RecieveCard = () => {
  const breakpoints = useBreakpoint()

  return (
    <WrapStyled>
      <SphereImageStyled src={sphereImage} alt="Sphere" />

      <ContainerLayout>
        {breakpoints.medium ? null : (
          <StarStyled src={fallingStar} alt="Falling star" />
        )}

        <TitleStyled>
          <b>{useI18n('gift.receive.title')}</b>
        </TitleStyled>
        <CaptionStyled>
          {useI18n('gift.receive.caption1')}
          <br />{useI18n('gift.receive.caption2')}
          <br />{useI18n('gift.receive.caption3')}
        </CaptionStyled>
        <BlockStyled>
          <ItemStyled>
          <ItemTitleStyled>{useI18n('gift.receive.first')}</ItemTitleStyled>
            <ItemDesc>
              {useI18n('gift.receive.details0')}
            </ItemDesc>
          </ItemStyled>
          <ItemStyled>
          <ItemTitleStyled>{useI18n('gift.receive.second')}</ItemTitleStyled>
            <ItemDesc>
              {useI18n('gift.receive.details2_0')}
            </ItemDesc>
          </ItemStyled>
        </BlockStyled>
        {/* <BlockStyled>
          <ItemStyled>
            <ItemTitleStyled>{useI18n('gift.receive.first')}</ItemTitleStyled>
            <ItemDescription>
              {useI18n('gift.receive.details1')}
              <br /> {useI18n('gift.receive.details2')}
              <br /> {useI18n('gift.receive.details3')}
              <br />{useI18n('gift.receive.title1')}
            </ItemDescription>
          </ItemStyled>
          <ItemStyled>
            <ItemTitleStyled>{useI18n('gift.receive.second')}</ItemTitleStyled>
            <ItemDescription>
              {useI18n('gift.receive.details5')}
              <br /> {useI18n('gift.receive.details6')}
              <br /> {useI18n('gift.receive.details7')}
            </ItemDescription>
          </ItemStyled>

          <HintBlockStyled>
            <RullerStyle src={ruler} alt="Ruler" />
            <HintTextStyled>Choose your reality...</HintTextStyled>
          </HintBlockStyled>
        </BlockStyled> */}
      </ContainerLayout>
    </WrapStyled>
  )
}
export default RecieveCard

const CaptionStyled = styled.div`
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 1.14;
  text-align: center;
  z-index: 1;
`;
const ItemDesc = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  min-height: 120px;

  position: relative;

  font-size: 1.1rem;
  text-align: center;
  line-height: 1.1;

  max-width: 350px;

  z-index: 1;
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 14px 30px;
  }

  @media ${props => props.theme.breakpoints.small} {
    width: 100%;
  }
`

const WrapStyled = styled.div`
  position: relative;
`

const TitleStyled = styled.p`
  margin-bottom: 2rem;

  font-size: 2.2rem;
  line-height: 1.28;
  text-align: center;

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`

const ItemDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 14px 60px;
  min-height: 120px;

  position: relative;

  font-size: 1.1rem;
  text-align: center;
  line-height: 1.1;

  z-index: 1;

  &:before {
    content: "";
    width: 24px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background-image: url(${greenBrackets});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(180deg);
  }

  &:after {
    content: "";
    height: 100%;
    width: 24px;
    position: absolute;
    top: 0;
    right: 0;

    background-image: url(${greenBrackets});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    padding: 14px 30px;
  }

  @media ${props => props.theme.breakpoints.small} {
    width: 100%;
  }
`
const BlockStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  gap: 80px;
  margin-top: 50px;
  margin-bottom: 20px;

  @media ${props => props.theme.breakpoints.small} {
    flex-direction: column;
    gap: 60px;
  }
`

const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media ${props => props.theme.breakpoints.small} {
    width: 100%;
  }
`

const ItemTitleStyled = styled.p`
  font-size: 1.4rem;
  font-family: ${props => props.theme.fonts.futuraDemi};

  margin-bottom: 1rem;
`
const SphereImageStyled = styled.img`
  width: 520px;
  height: 520px;
  transform: translateX(35%);

  position: absolute;
  top: -50%;
  right: 0%;

  opacity: 0.2;

  @media ${props => props.theme.breakpoints.tablet} {
    width: 260px;
    height: 260px;
    transform: translateX(60%);

    top: 10%;
  }
`
const StarStyled = styled.img`
  width: 140px;
  height: 80px;

  position: absolute;
  top: 3%;
  right: 18%;
`

const HintBlockStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  width: 100%;

  position: absolute;
  bottom: -150px;
  @media ${props => props.theme.breakpoints.small} {
    bottom: -100px;
  }
`
const RullerStyle = styled.img`
  width: 100%;
  margin-bottom: 1rem;

  @media ${props => props.theme.breakpoints.small} {
    width: 270%;
  }
`

const HintTextStyled = styled.p`
  color: #151c1a;
  font-size: 1.2rem;
  font-family: ${props => props.theme.fonts.futuraDemi};
`
