import * as React from "react";

import styled from "styled-components";

import NavBar from "../../components/navbar";
import Landing from "./Landing";
import Card from "./Card";
import RecieveCard from "./RecieveCard";
import CardDescription from "./CardDescription";
// import About from "./About";
import Contacts from "./Contacts";
import Seo from "../../components/seo";
import { ServerDataContext } from '../../../serverData';
import { graphql } from "gatsby";

// IF NEEDED REVIEWS - USE components/reviews
// check mockups about About component

const Certificate = ({ pageContext }) => {
  return (
    <ServerDataContext.Consumer>
      {({ dataLoaded, getData }) => {
        if (!dataLoaded) getData(pageContext.companyId);
        return (
          <ContainerStyled id="gift-cards">
            <NavBar city={pageContext} isGiftcard />
            <Landing city={pageContext} />
            <CardDescription />
            <Card city={pageContext} />
            <RecieveCard />
            
            {/* <About /> */}
            <Contacts city={pageContext} />
          </ContainerStyled>
        );
      }}
    </ServerDataContext.Consumer>
  );
};

export const Head = ({ pageContext }) => (
  <>
    <SeoData context={pageContext} />
    <script
      dangerouslySetInnerHTML={{
        __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '1258133411516313'); 
fbq('track', 'PageView');
            `
      }}
    />
    <noscript>
      <img height="1" width="1"
        src="https://www.facebook.com/tr?id=1258133411516313&ev=PageView
&noscript=1"/>
    </noscript>
    {!!pageContext.fbPersonalId &&
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '${pageContext.fbPersonalId}'); 
fbq('track', 'PageView');
            `
          }}
        />
        <noscript>
          <img height="1" width="1"
            src={`https://www.facebook.com/tr?id=${pageContext.fbPersonalId}&ev=PageView
&noscript=1`} />
        </noscript>
      </>
    }
  </>
)
const SeoData = ({ context }) => {
  const [lang, setLang] = React.useState('ua')
  return (
          <Seo
          context={context}
          title={`Подарунковий сетифікат у Matrix VR ${context.name[lang]}`}
          description={`Шукаєте крутий подарунок своїм близьким? Ми допоможемо вам та пропонуємо купити сертифікат у клуб віртуальної реальності в ${context.declension[lang]}!`}
         />
  )
}

export default Certificate;

const ContainerStyled = styled.div`
  min-height: 100vh;
  background: #030303;
  overflow: hidden;
`;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;