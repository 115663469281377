import React, { useState } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import ModalWindow from './OrderCardModal'
import ContainerLayout from '../../components/ui/containerLayout'
import Button from '../../components/ui/buttons'

import { useI18n } from '../../../locale.js'

import cardBrackets from '../../assets/images/elements/certificateBracketsDesktop.png'
import cardBracketsMobile from '../../assets/images/elements/certificateBracketsMobile.png'
import greyCubes from '../../assets/images/elements/greyCubesCloud.png'
import greenCodeStrings from '../../assets/images/elements/greenCodeStrings.png'
import greyCodeStrings from '../../assets/images/elements/greyCodeStrings.png'

const Card = ({ city }) => {
  const breakpoints = useBreakpoint()

  const [showModal, setShowModal] = useState(false)

  return (
    <ContainerLayout id='booking'>
      <ModalWindow
        city={city}
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />

      <ImageCubesStyled src={greyCubes} alt='Grey cubes' />

      <CardStyled>
        <CardTitleStyled>
          {useI18n('gift.card.title1')}
          <br />
          {useI18n('gift.card.title2')}
        </CardTitleStyled>
        <CardDescriptionStyled>
          {useI18n('gift.card.description1')}
          <br /> {useI18n('gift.card.description2')}
        </CardDescriptionStyled>
        <ButtonStyled
          variant='solid'
          title={useI18n('gift.card.orderCard')}
          onClick={() => setShowModal(!showModal)}
        />
      </CardStyled>
      {breakpoints.tablet ? null : <HintTextStyled>Start game</HintTextStyled>}

      {breakpoints.tablet ? null : (
        <CodeStringsImageStyled src={greenCodeStrings} alt='Code strings' />
      )}

      <CodeStringsGreyImageStyled
        src={greyCodeStrings}
        alt='Code strings grey'
      />
    </ContainerLayout>
  )
}
export default Card

const CardStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 80px 135px;

  background-image: url(${cardBrackets});
  background-size: contain;
  background-repeat: no-repeat;

  @media ${(props) => props.theme.breakpoints.small} {
    padding: 110px 40px;
    background-image: url(${cardBracketsMobile});
  }
  @media ${(props) => props.theme.breakpoints.mobile} {
    width: 100%;
    padding: 150px 40px;
  }
  @media ${(props) => props.theme.breakpoints.xsmall} {
    padding: 110px 30px;
  }
`

const CardTitleStyled = styled.div`
  margin-bottom: 2rem;

  font-size: 1.5rem;
  text-align: center;
  font-family: ${(props) => props.theme.fonts.futuraDemi};
  color: ${(props) => props.theme.colors.green};

  @media ${(props) => props.theme.breakpoints.small} {
    margin-bottom: 1.2rem;

    font-size: 1.2rem;
  }
`
const CardDescriptionStyled = styled.p`
  margin-bottom: 2rem;

  font-size: 1.1rem;
  line-height: 1.1;
  text-align: center;
  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 0.9rem;
    margin-bottom: 4rem;
  }
`
const ButtonStyled = styled(Button)`
  width: 100%;
`

const HintTextStyled = styled.p`
  position: absolute;
  bottom: 50px;

  color: #151c1a;
  font-size: 1.2rem;
  font-family: ${(props) => props.theme.fonts.futuraDemi};
`
const ImageCubesStyled = styled.img`
  width: 160px;

  position: absolute;
  left: -19%;
  top: -12%;
  transform: rotate(90deg);

  @media ${(props) => props.theme.breakpoints.small} {
    top: 85%;
  }
`

const CodeStringsImageStyled = styled.img`
  width: 110px;
  height: 290px;

  position: absolute;
  right: 10%;
  top: 0%;
`
const CodeStringsGreyImageStyled = styled.img`
  width: 200px;
  height: 380px;

  position: absolute;
  left: 2%;
  bottom: -17%;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 120px;
    height: 200px;

    left: unset;
    bottom: unset;
    top: 0;
    right: -5%;
  }
`
