import React from "react";

import styled from "styled-components";

// import FacebookIcon from "../../assets/icons/facebookIcon";
import InstagramIcon from "../../assets/icons/instagramIcon";

const SocialsBlock = ({ showOnMobile, showAlways }) => {
  return (
    <ContainerStyled showOnMobile={showOnMobile} showAlways={showAlways}>
      {/* <LinkStyled href="" target="_blank" rel="noopener noreferrer">
        <FacebookIcon />
      </LinkStyled> */}
      <LinkStyled
        href="https://www.instagram.com/matrixvr.official/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon />
      </LinkStyled>
    </ContainerStyled>
  );
};
export default SocialsBlock;

const ContainerStyled = styled.div`
  display: ${(props) =>
    props.showOnMobile && !props.showAlways ? "none" : "flex"};

  gap: 15px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    display: ${(props) => (props.showOnMobile ? "flex" : "none")};

    margin: 50px auto 100px;
  }
`;

const LinkStyled = styled.a`
  display: flex;
  &:hover {
    svg path {
      fill: ${(props) => props.theme.colors.green};
      transition: 0.3s ease-in-out;
    }
  }
`;
