import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import Modal from "react-modal";
import Select from "react-select";
import { navigate } from "gatsby";
import NumberFormat from 'react-number-format';
import axios from 'axios'

import { useI18n, LocaleContext } from "../../../locale.js"
import { ServerDataContext } from '../../../serverData';

import Button from "../../components/ui/buttons";
import plusIcon from "../../assets/icons/plusIcon.png";
import minusIcon from "../../assets/icons/minusIcon.png";
import { I18nextContext } from "gatsby-plugin-react-i18next";
import { useGoogleReCaptcha, GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

import Recaptcha from 'react-recaptcha';

const ModalWindow = ({ showModal, onClose, city }) => {
  const { locale } = useContext(LocaleContext);
  const context = React.useContext(I18nextContext);
  const { details } = useContext(ServerDataContext);
  const [name, setname] = useState('');
  const [comment, setComment] = useState('');
  const [phone, setphone] = useState('');
  const [numberOfCards, setnumberOfCards] = useState(1)
  const [location, setlocation] = useState({});
  const [isPhoneValid, setisPhoneValid] = useState(true);
  const [allowEmptyFormatting, setallowEmptyFormatting] = useState(false);
  const [executeRecaptcha, setExecureRecaptcha] = useState(false);
  const [token, setToken] = useState('')

  const locationData = details?.addresses || city.locations;

  const getLocationString = address => {
    const addon = address[context.language]?.address1 ? `, ${address[context.language]?.address1}` : '';
    return address[context.language]?.address + addon;
  }

  useEffect(() => {
    if (locationData.length === 1) {
      setlocation({
        value: locationData[0].companyId,
        label: locationData[0].address ? getLocationString(locationData[0].address) : locationData[0].address1[context.language]
      });
    } else {
      setlocation({})
    }
  }, [city]);

  // Fallback with cities.json data
  const options = locationData.map(c => ({
    label: c.address ? getLocationString(c.address) : c.address1[context.language],
    value: c.companyId
  }))

  const onCardChange = (change) => {
    const newValue = numberOfCards + change

    if (newValue > 0) {
      setnumberOfCards(newValue)
    }
  }

  // const getToken = async () => {
  //   const result = await executeRecaptcha('gift-card-order-page')
  //   console.log("result", result)
  //   setToken(result)
  // }

  const reCaptchaCallback = (response) => {
    setExecureRecaptcha(true)
  };

  const onSubmit = (e) => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha || String(phone.value).length < 10) {
      return
    }

    // getToken()

    if (!/^\d{10}$/.test(phone.value)) {
      setisPhoneValid(false)
      return
    }

    const payload = {
      type: 'certificate',
      phone: phone.value,
      name,
      noCallback: false,
      q: window.location.search,
      ...(comment && { details: comment })
    }

    axios.post('https://us-central1-vr-crm.cloudfunctions.net/newBookingRequest', {
      // axios.post('/api/order-card', {
      companyId: location.value || city.companyId,
      bookingData: payload,
      token: token
    })
      .then(() => {
        // Ping GTM
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ 'event': 'formsendVR' })
        // Go to Thank You page
        navigate('/' + city.slug + '/thank-you')
      })
  }

  const calcPrice = () => {
    const price = details?.schedule ? (details.schedule.sunday[0].custom?.certificate || details.schedule.sunday[0].price) : city.prices.weekend;
    return price * numberOfCards;
  }

  return (
    // <GoogleReCaptchaProvider reCaptchaKey="6Lf31ZklAAAAAPpgpM9s16vvfzPatOBpp_Vv_KwN">
    <ModalStyled
      isOpen={showModal}
      onRequestClose={onClose}
      style={{
        overlay: {
          zIndex: "100",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
      ariaHideApp={false}
    >
      <ModalBackgroundStyled onClick={onClose} />
      <ModalBodyStyled>
        <CloseButtonStyled onClick={onClose} />

        <ModalTitleStyled>{useI18n('gift.modal.order')}</ModalTitleStyled>
        <FormStyled onSubmit={(e) => e.preventDefault()}>
          {/* <GoogleReCaptcha
              onVerify={token => {
                setToken(token);
              }}
            /> */}
          <InputStyled
            type="text"
            placeholder={useI18n('gift.modal.name')}
            value={name}
            onChange={e => setname(e.target.value)}
          />

          <NumberFormat
            customInput={InputStyled}
            placeholder={useI18n('gift.modal.phone')}
            format="+38 (###) ###-##-##"
            mask="_"
            type="text"
            value={phone.formattedValue}
            allowEmptyFormatting={allowEmptyFormatting}
            onFocus={() => {
              if (!allowEmptyFormatting) {
                setallowEmptyFormatting(true)
              }
            }}
            onBlur={() => {
              setallowEmptyFormatting(false)
            }}
            onValueChange={value => {
              setphone(value)
              setisPhoneValid(true)
            }}
            style={isPhoneValid ? {} : { border: "1px solid #eb516d" }}
          />

          <RowStyled>
            <LabelStyled>{useI18n('gift.modal.quantity')}</LabelStyled>
            <NumberInputStyled>
              <NumberButtonStyled onClick={() => onCardChange(-1)}>
                <img src={minusIcon} alt="Minus" />
              </NumberButtonStyled>
              <p>{numberOfCards}</p>
              <NumberButtonStyled onClick={() => onCardChange(1)}>
                <img src={plusIcon} alt="Plus" />
              </NumberButtonStyled>
            </NumberInputStyled>
            <TotalPriceStyled>{calcPrice()}.00 {useI18n('gift.modal.uah')}</TotalPriceStyled>
          </RowStyled>

          <SelectStyled
            options={options}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            value={options.find(c => c.value === location.value)}
            onChange={option => setlocation(option)}
            classNamePrefix="select"
            isSearchable={false}
            placeholder={useI18n('gift.modal.choose_club')}
          />

          <TextAreaStyled
            value={comment}
            type="text"
            placeholder={useI18n('gift.modal.note')}
            rows={4}
            onChange={e => setComment(e.target.value)}
          />
          <Recaptcha
            sitekey="6Lf31ZklAAAAAPpgpM9s16vvfzPatOBpp_Vv_KwN"
            render="explicit"
            verifyCallback={reCaptchaCallback}
          />
          <div style={{marginBottom: '20px'}}></div>
          <ButtonStyled
            disabled={!executeRecaptcha || String(phone.value).length < 10}
            variant="solid"
            title={useI18n('gift.modal.send')}
            onClick={onSubmit}
            type="submit"
          />
        </FormStyled>
      </ModalBodyStyled>
    </ModalStyled>
    // </GoogleReCaptchaProvider>
  );
};

export default ModalWindow;

const ModalStyled = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;
const ModalBackgroundStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 550px;
  width: 100%;
  margin: auto;
  padding: 80px;

  position: relative;

  background-color: #fff;

  z-index: 1;

  @media ${(props) => props.theme.breakpoints.small} {
    height: 100vh;
    padding: 40px 60px;
    max-width: 100%;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 40px 20px;
  }
`;
const FormStyled = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;
const CloseButtonStyled = styled.button`
  width: 25px;
  height: 25px;
  padding: 0px;

  position: absolute;
  right: 32px;
  top: 32px;

  background: none;
  border: none;

  opacity: 0.3;
  transition: 0.3s all;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    content: " ";
    height: 26px;
    width: 2px;

    position: absolute;
    top: 0;
    left: 11px;

    background-color: ${(props) => props.theme.colors.green};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    right: 20px;
    top: 20px;
  }
`;

const ModalTitleStyled = styled.p`
  margin-bottom: 50px;

  font-size: 1.8rem;
  color: ${(props) => props.theme.colors.black};
  font-family: ${(props) => props.theme.fonts.futuraDemi};

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.6rem;
  }
`;

const InputStyled = styled.input`
  padding: 6px 15px;
  margin-bottom: 25px;

  border: 1px solid #6e6e6e;

  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.black};
  outline: none;
`;
const TextAreaStyled = styled.textarea`
  padding: 6px 15px;
  margin-bottom: 25px;
  height: 80px;

  border: 1px solid #6e6e6e;

  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.black};
  resize: none;

  outline: none;
`;

const SelectStyled = styled(Select)`
  .select__control {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    cursor: pointer;

    padding: 4px 15px 4px 7px;
    margin-bottom: 25px;
    margin-top: 25px;

    border: 1px solid #6e6e6e !important;
    border-radius: unset;

    font-size: 0.9rem;
    color: ${(props) => props.theme.colors.black};

    &:after {
      content: "";

      width: 0;
      height: 0;
      margin-left: auto;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #6e6e6e;
      z-index: 1;
    }
  }
  .select__single-value {
    color: ${(props) => props.theme.colors.black};
    margin: 0;
  }
  .select__menu {
    border-radius: 0;
    padding: 0;
    margin-top: -20px;
  }

  .select__option {
    background-color: ${(props) => props.theme.colors.white}!important;
    cursor: pointer;
    color: ${(props) => props.theme.colors.black};
    &:hover {
      color: ${(props) => props.theme.colors.green};
    }
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    .select__menu {
      margin-top: -20px;
    }
  }
`;

const ButtonStyled = styled(Button)`
  @media ${(props) => props.theme.breakpoints.small} {
    width: 100%;
    margin: auto;
  }
`;

const RowStyled = styled.div`
  display: flex;
  align-items: center;
`;
const LabelStyled = styled.p`
  color: ${(props) => props.theme.colors.black};
  font-size: 1rem;
`;

const NumberInputStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 6px;
  margin-left: 15px;
  gap: 5px;

  border: 1px solid #6e6e6e;

  color: ${(props) => props.theme.colors.black};
  line-height: 1.25;
  font-size: 1.2rem;

  > * {
    display: flex;
    flex: 1;
  }
`;
const NumberButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;

  border: none;
  background: unset;
`;

const TotalPriceStyled = styled.p`
  margin-left: auto;

  color: ${(props) => props.theme.colors.black};
  font-family: ${(props) => props.theme.fonts.futuraDemi};
  line-height: 1.25;
  font-size: 1.2rem;
`;
